import React, { Component } from 'react';
import Layout from "../components/layout";
import Intro from "../components/intro";
import PracticeCategory from "../components/practice-category";
import Nav from '../components/nav';

class IndexPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            single_items: {},
            isSingleLoaded: false,
            current_path: null
        }
    }

    async componentDidMount() {
        const id = this.props
        console.log("componentDidMount", JSON.stringify(id))
        console.log("componentDidMount params", JSON.stringify(id.params))
        let CMSId = id.params["*"];
        this.getDetailsById(CMSId);
        this.getAllDetails();
        console.log("componentDidMount", this.props)
        this.setState({ current_path: this.props.location })
    }

    componentWillReceiveProps(nextProps) {
        console.log("App componentWillReceiveProps nextProps", nextProps.params["*"])
        let id = nextProps.params["*"];
        this.getDetailsById(id);
        this.getAllDetails();
        console.log("nextProps", nextProps)
        this.setState({ current_path: nextProps.location })
    }

    getAllDetails() {

        fetch("https://tatva.com/backendapi/api/v1/cms/by-type/practice")
            .then(res => res.json())
            .then(
                (result) => {
                    const filteredDates = result.data.sort((a, b) => new Date(b.order_id) - new Date(a.order_id))
                    console.log("filteredDates", filteredDates)
                    this.setState({
                        isLoaded: true,
                        items: filteredDates
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    getDetailsById(id) {
        fetch("https://tatva.com/backendapi/api/v1/cms/by-slug/" + id)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isSingleLoaded: true,
                        single_items: result
                    });
                },
                (error) => {
                    this.setState({
                        isSingleLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const { isLoaded, isSingleLoaded, items, single_items, current_path } = this.state;
        return (

            <Layout>
                {isSingleLoaded ?
                <Nav
                    active="practice"
                    pageTitle={'Our Practice Areas'}
                    title={single_items.data.document_title}
                    description={single_items.data.meta_description}
                    keywords={single_items.data.meta_tag}
                >
                </Nav>
                : ''}

                <Intro title="Our Practice Areas"
                    description="<p>We are uniquely positioned to support businesses, operations and investments in the states of Telangana and Andhra Pradesh owing to our unparalleled reach, insights, experience and expertise in this region, including on local laws and practices.</p>
                         <p>We provide the entire range of legal services to our clients. The areas that we specialise in include: </p>"
                > </Intro>

                <div className="main-container" id="practice-page">
                    <div className="our-practices-image">

                        {isLoaded ?
                            <div id="practice-navigation-wrapper">
                                <PracticeCategory active={current_path} data={items}> </PracticeCategory>
                            </div>
                            : ''}

                        <div className="container">
                            {isSingleLoaded ?
                                <div className="our-pratice-outer">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12" id={single_items.data.slug}>
                                            <h1 className="ourPracticeAreaHeading">{single_items.data.title}</h1>
                                            <div className="inner">
                                                <div dangerouslySetInnerHTML={{ __html: single_items.data.description }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''}

                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default IndexPage;
