import React, { useState } from 'react';
import { Link } from "gatsby"

export default function PracticeCategory(props) {

    const setActive = ({ location: { pathname } }, active) => {

        console.log("props", props)
        // console.log("location", location)
        console.log("pathname", pathname)

        if (pathname === pathname) {
            return { className: "active" }
        } else {
            return { className: "" }
        }
    }


    const [isVisible, setIsVisible] = useState(false);
    const MegaMenu = () => {
        setIsVisible(!isVisible);
    }

    return (

        <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="practice_navigation text-center">
                        <div className="desktop">
                            <ul>
                                {props.data.map(item => (
                                    <li key={item._id} data-path={"/our-practice-areas" + props.active.pathname} >
                                        <Link
                                            className={props.active.pathname == "/our-practice-areas/" + item.slug + "/" ? 'active' : 'non-active'}
                                            to={"/our-practice-areas/" + item.slug + "/#middle_content"}>
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="mobile"  style={{width:220 * props.data.length + "px"}}>
                            <ul>
                                {props.data.map(item => (
                                    <li key={item._id} data-path={"/our-practice-areas" + props.active.pathname} >
                                        <Link
                                            className={props.active.pathname == "/our-practice-areas/" + item.slug + "/" ? 'active' : 'non-active'}
                                            to={"/our-practice-areas/" + item.slug + "/#middle_content"}>
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>


                        {/* <div className="mobile">
                        {isVisible ?
                            <React.Fragment>
                                <div className="sub-navigation"> Our Practice Areas
                                    <button className="close" onClick={() => MegaMenu()}> </button>
                                </div>
                                <ul>
                                    {props.data.map(item => (
                                        <li key={item._id} data-active={props.active.pathname} onClick={() => MegaMenu()}>
                                            <Link  activeClassName="active" to={"/our-practice-areas/" + item.slug + "/#middle_content"}>{item.title}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </React.Fragment> :
                            <React.Fragment>
                                <div className="sub-navigation"> Our Practice Areas
                                    <button className="menu_mobile" onClick={() => MegaMenu()}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button>
                                </div>
                            </React.Fragment>
                        }
                    </div> */}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
